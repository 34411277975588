import * as yup from "yup";

//Login Validation schema admin
export const validationSchemaLogin = yup.object().shape({
  email: yup
    .string()
    .test(
      "is-email-or-username",
      "Please enter a valid email or username",
      function (value) {
        // Define your email and username regex patterns
        const emailRegex = /^[a-zA-Z0-9]{1,25}$/;
        const usernameRegex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // Check if the value matches either the email or username pattern
        if (emailRegex.test(value) || usernameRegex.test(value)) {
          return true; // Validation passes
        } else {
          return false; // Validation fails
        }
      }
    )
    .required("Email or username is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Must contain 8 characters")
    .max(20, "Password cannot exceed 20 characters")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase letter")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase letter")
    .matches(/^(?=.*\d)/, "Must contain at least one digit")
    .matches(/^(?=.*[@$!%*?&])/, "Must contain at least one special character")
    .test(
      "strong-password",
      "Password must meet all criteria",
      function (value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/.test(value);
      }
    ),
});

//forgot password
export const validationSchemaForgotpassword = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
    .required("Email is required"),
});

// reset password admin side
export const validationSchemaResetPassword = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Must contain 8 characters")
    .max(20, "Password cannot exceed 20 characters")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase letter")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase letter")
    .matches(/^(?=.*\d)/, "Must contain at least one digit")
    .matches(/^(?=.*[@$!%*?&])/, "Must contain at least one special character")
    .test(
      "strong-password",
      "Password must meet all criteria",
      function (value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/.test(value);
      }
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

// change password
export const validationSchemaChangepassword = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .min(8, "Must contain 8 characters")
    .max(20, "Password cannot exceed 20 characters")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase letter")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase letter")
    .matches(/^(?=.*\d)/, "Must contain at least one digit")
    .matches(/^(?=.*[@$!%*?&])/, "Must contain at least one special character")
    .test(
      "strong-password",
      "Password must meet all criteria",
      function (value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/.test(value);
      }
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export const validationSchemaYearOfSahay = yup.object().shape({
  fromYear: yup
    .string()
    .required("From year field is required")
    .matches(/^[0-9]{4}$/, "From year must be a valid 4-digit year")
    .test("is-past-year", "From year must be a past year", function (value) {
      if (value) {
        const currentYear = new Date().getFullYear();
        return parseInt(value, 10) <= currentYear;
      }
      return true; // Allow when the value is empty
    }),
  toYear: yup
    .string()
    .required("To year field is required")
    .matches(/^[0-9]{4}$/, "To year must be a valid 4-digit year")
    .test(
      "is-greater",
      "To year cannot be greater than From year",
      function (value) {
        const { fromYear } = this.parent; // Access the value of 'fromYear' in the parent object
        if (fromYear && value) {
          return parseInt(value, 10) >= parseInt(fromYear, 10);
        }
        return true; // Allow when either value is empty
      }
    )
    .test(
      "is-next-year",
      "To year must be the next year of From year",
      function (value) {
        const { fromYear } = this.parent; // Access the value of 'fromYear' in the parent object
        if (fromYear && value) {
          return parseInt(value, 10) === parseInt(fromYear, 10) + 1;
        }
        return true; // Allow when either value is empty
      }
    ),
});

//Login Validation schema
export const validationSchemaUserLogin = yup.object().shape({
  number: yup
    .string()
    .required("Number is required")
    .min(10, "Number should be 10 digits"),
});

//year of sahay
export const validationSchemaApplyForSahay = yup.object().shape({
  // formFillingDate : yup.date().required("Filling Date is required"),
  // applicationReceiveDate : yup.date().required("Application Recive Date is required"),
  familyId: yup.string().required("Family Id is required"),
  AadharNo: yup
    .string()
    .required("Aadhar Number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{12}$/, "Please enter valid  aadhar number"),
  SahayType: yup.string().required("Sahay type is required"),
  subSahayType: yup.string().required("Sub sahay type is required"),
  memberName: yup.string().required("Name is required"),
  // age: yup.number().required("Age is required"),
  workingType: yup.string().required("Working type is required"),
  bankName: yup.string().required("Bank name is required"),
  applicantId: yup.string().required("Name of applicant is required"),
  beneficiaryName: yup
    .string()
    .required("Beneficiary Name of applicant is required"),
  threeMonthBillAmount: yup
    .string()
    .required("Electric Bill amount is required"),
  monthlyIncome: yup.string().required("Family income is required"),
  branch: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      "branch name should contain only alphabet characters"
    )
    .required("Branch is required"),
  mediclaimAmmount: yup
    .string()
    .max(5, "Mediclaim amount cannot exceed 5 digits")
    .matches(/^[0-9]+$/, "Mediclaim amount must contain only numbers"),
  HolderName: yup
    .string()
    .required("Account holder name is required")
    .max(50, "Account holder name cannot exceed 50 characters")
    .matches(
      /^[A-Za-z\s]+$/,
      "Account holder name should contain only alphabet characters"
    )
    // .matches(/^[A-Za-z]+( [A-Za-z]+)*$/, "Account holder name should contain only alphabet characters")
    .matches(
      /^[^0-9\+-.]+$/,
      "Account holder name cannot contain numbers, special characters, plus, minus, or dots"
    ),

  IFSCCode: yup
    .string()
    .required("IFSC code is required")
    // .matches(
    //   /^[A-Z]{4}0[A-Z0-9]{6}$/,
    //   "Please enter a valid IFSC code such as ABCD0123HYF"
    // ),
    .matches(
      /(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9]{1,11}$/,
      "Please enter a valid IFSC code such as ABCD0123HYF"
    ),
  // .matches(/^[A-Z]+\d+[A-Z]+$/,"Please enter a valid IFSC code such as ABCD0123HYF"),
  AccountNo: yup.string().required("Account number is required"),
  // passportPic: yup
  //   .mixed()
  //   .test(
  //     "FileType",
  //     "Please enter valid file type (Jpeg,jpg,png file)",
  //     (value) => {
  //       if (!value) return true;
  //       if (value?.type) {
  //         return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
  //       } else {
  //         let val = value?.split(".")[1];
  //         return ["jpeg", "jpg", "png"].includes(val);
  //       }
  //     }
  //   ),
  // .required("Image is required"),
  // documents: yup.array().of(
  //   yup.object().shape({
  //     isUpload: yup.boolean(),
  //     file: yup.mixed().when('isUpload', {
  //       is: true,
  //       then: () => yup.mixed().required("Document is required").test({
  //         name: "fileType",
  //         message: "Unsupported file format. Accepted only jpg, jpeg, png, pdf.",
  //         test: function (value) {
  //           console.log("values.documents111",value);
  //           // const docValue = value?.FileList?.map((val)=>{
  //           //   return val
  //           // })

  //           // console.log("docValue",docValue);
  //           // if (!value) return true;
  //           value?.map((val)=>{
  //             console.log("values.documents111___vallllll",val);
  //             console.log("values.documents111___vallllll.name",val.name);
  //             if(val?.name){
  //               const supportedFormats = ["jpg", "jpeg", "png", "pdf"];
  //               const extension = val?.name?.split(".")?.pop().toLowerCase();
  //               return supportedFormats.includes(extension);
  //             }else{
  //                 const supportedFormats = ["jpg", "jpeg", "png", "pdf"];
  //                 const extension = val?.name?.split(".")?.pop()?.toLowerCase();
  //                 return supportedFormats.includes(extension);
  //               }
  //           })

  //         },
  //       }),
  //       otherwise: () => yup.mixed(),
  //     }),
  //   })
  // ),
  documents: yup.array().of(
    yup.object().shape({
      isUpload: yup.boolean(),
      file: yup.mixed().test({
        name: "fileValidation",
        test: function (value) {
          if (this.parent.isUpload) {
            // If isUpload is true, perform validation
            if (!value || value.length === 0) {
              // If no file uploaded, return false with error message
              throw this.createError({
                message: "Document is required",
              });
            }

            const supportedFormats = ["jpg", "jpeg", "png", "pdf"];
            
            const invalidFiles = value?.filter((file) => {
              let extension;
              if (file?.filename) {
                extension = file?.filename?.split(".")?.pop()?.toLowerCase();
              } else {
                extension = file?.name?.split(".")?.pop()?.toLowerCase();
              }
              return !supportedFormats.includes(extension);
            });

            if (invalidFiles.length > 0) {
              // If there are invalid files, throw error with custom message
              throw this.createError({
                message:
                  "Please upload file in 'jpg', 'jpeg', 'png', or 'pdf' format",
              });
            }

            // If all files are valid, return true
            return true;
          } else {
            // If isUpload is false, return true (no validation needed)
            return true;
          }
        },
      }),
    })
  ),

  yearOfSahay: yup.string().required("Year of sahay is required"),
});

export const validationSchemaApplyForSahayBankMember = yup.object().shape({
  // formFillingDate : yup.date().required("Filling Date is required"),
  // applicationReceiveDate : yup.date().required("Application Recive Date is required"),
  AadharNo: yup
    .string()
    .required("Aadhar number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{12}$/, "Please enter valid aadhar number"),
  SahayType: yup.string().required("Sahay type is required"),
  subSahayType: yup.string().required("Sub sahay type is required"),
  applicantId: yup.string().required("Name of applicant is required"),
  yearOfSahay: yup.string().required("Year of sahay is required"),
  mediclaimAmmount: yup
    .string()
    .max(5, "Mediclaim amount cannot exceed 5 digits")
    .matches(/^[0-9]+$/, "Mediclaim amount must contain only numbers"),
});

////YEAR OF SAHAY USER ////////////////

export const validationSchemaApplyForSahayUser = yup.object().shape({
  // formFillingDate : yup.date().required("Filling Date is required"),
  // applicationReceiveDate : yup.date().required("Application Recive Date is required"),
  familyId: yup.string().required("Family Id is required"),
  AadharNo: yup
    .string()
    .required("Aadhar Number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{12}$/, "Please enter valid  aadhar number"),
  SahayType: yup.string().required("Sahay type is required"),
  subSahayType: yup.string().required("Sub sahay type is required"),
  memberName: yup.string().required("Name is required"),
  // age: yup.number().required("Age is required"),
  workingType: yup.string().required("Working type is required"),
  bankName: yup.string().required("Bank name is required"),
  applicantId: yup.string().required("Name of applicant is required"),
  beneficiaryName: yup
    .string()
    .required("Beneficiary Name of applicant is required"),
  threeMonthBillAmount: yup
    .string()
    .required("Electric Bill amount is required"),
  monthlyIncome: yup.string().required("Family income is required"),
  branch: yup
    .string()
    .matches(
      /^[A-Za-z]+$/,
      "branch name should contain only alphabet characters"
    )
    .required("Branch is required"),
  mediclaimAmmount: yup
    .string()
    .max(5, "Mediclaim amount cannot exceed 5 digits")
    .matches(/^[0-9]+$/, "Mediclaim amount must contain only numbers"),
  HolderName: yup
    .string()
    .required("Account holder name is required")
    .max(50, "Account holder name cannot exceed 50 characters")
    .matches(
      /^[A-Za-z\s]+$/,
      "Account holder name should contain only alphabet characters"
    )
    // .matches(/^[A-Za-z]+( [A-Za-z]+)*$/, "Account holder name should contain only alphabet characters")
    .matches(
      /^[^0-9\+-.]+$/,
      "Account holder name cannot contain numbers, special characters, plus, minus, or dots"
    ),

  IFSCCode: yup
    .string()
    .required("IFSC code is required")
    // .matches(
    //   /^[A-Z]{4}0[A-Z0-9]{6}$/,
    //   "Please enter a valid IFSC code such as ABCD0123HYF"
    // ),
    .matches(
      /(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9]{1,11}$/,
      "Please enter a valid IFSC code such as ABCD0123HYF"
    ),
  // .matches(/^[A-Z]+\d+[A-Z]+$/,"Please enter a valid IFSC code such as ABCD0123HYF"),
  AccountNo: yup.string().required("Account number is required"),
  // documents: yup.array().of(
  //   yup.object().shape({
  //     isUpload: yup.boolean(),
  //     file: yup.mixed().when("isUpload", {
  //       is: true,
  //       then: () =>
  //         yup
  //           .mixed()
  //           .required("Document is required")
  //           .test({
  //             name: "fileType",
  //             message:
  //               "Unsupported file format. Accepted only jpg, jpeg, png, pdf.",
  //             test: function (value) {
  //               if (!value) return true;
  //               if (value?.name) {
  //                 const supportedFormats = ["jpg", "jpeg", "png", "pdf"];
  //                 const extension = value.name.split(".").pop().toLowerCase();
  //                 return supportedFormats.includes(extension);
  //               } else {
  //                 const supportedFormats = ["jpg", "jpeg", "png", "pdf"];
  //                 const extension = value?.name?.split(".")?.pop()?.toLowerCase();
  //                 return supportedFormats.includes(extension);
  //               }
  //             },
  //           }),
  //       otherwise: () => yup.mixed(),
  //     }),
  //   })
  // ),

  documents: yup.array().of(
    yup.object().shape({
      isUpload: yup.boolean(),
      file: yup.mixed().test({
        name: "fileValidation",
        test: function (value) {
          if (this.parent.isUpload) {
            // If isUpload is true, perform validation
            if (!value || value.length === 0) {
              // If no file uploaded, return false with error message
              throw this.createError({
                message: "Document is required",
              });
            }

            const supportedFormats = ["jpg", "jpeg", "png", "pdf"];
            const invalidFiles = value.filter((file) => {
              let extension;
              if (file?.filename) {
                extension = file?.filename?.split(".")?.pop()?.toLowerCase();
              } else {
                extension = file?.name?.split(".")?.pop()?.toLowerCase();
              }
              return !supportedFormats.includes(extension);
            });

            if (invalidFiles.length > 0) {
              // If there are invalid files, throw error with custom message
              throw this.createError({
                message:
                  "Please upload file in 'jpg', 'jpeg', 'png', or 'pdf' format",
              });
            }

            // If all files are valid, return true
            return true;
          } else {
            // If isUpload is false, return true (no validation needed)
            return true;
          }
        },
      }),
    })
  ),


  yearOfSahay: yup.string().required("Year of sahay is required"),
});

export const validationSchemaApplyForSahayBankMemberUser = yup.object().shape({
  // formFillingDate : yup.date().required("Filling Date is required"),
  // applicationReceiveDate : yup.date().required("Application Recive Date is required"),
  AadharNo: yup
    .string()
    .required("Aadhar number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{12}$/, "Please enter valid aadhar number"),
  SahayType: yup.string().required("Sahay type is required"),
  subSahayType: yup.string().required("Sub sahay type is required"),
  applicantId: yup.string().required("Name of applicant is required"),
  yearOfSahay: yup.string().required("Year of sahay is required"),
  mediclaimAmmount: yup
    .string()
    .max(5, "Mediclaim amount cannot exceed 5 digits")
    .matches(/^[0-9]+$/, "Mediclaim amount must contain only numbers"),
});










//New Member
export const validationSchemaNewMember = yup.object().shape({
  mobileNumber: yup
    .string()
    .required("Mobile number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid mobile number"),
  email: yup
    .string()
    .email("Please enter valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
    .required("Email is required"),
  firstName: yup
    .string()
    .required("First name is required")
    .max(20, "First name cannot exceed 20 characters")
    .matches(/^[A-Za-z]+$/, "First name should contain only letters"),
  middleName: yup
    .string()
    .required("Middle name is required")
    .max(20, "Middle name cannot exceed 20 characters")
    .matches(/^[A-Za-z]+$/, "Middle name should contain only letters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(20, "Last name cannot exceed 20 characters")
    .matches(/^[A-Za-z]+$/, "Last name should contain only letters"),
  DOB: yup
    .string()
    .required("DOB is required")
    .test(
      "is-not-future-date",
      "DOB cannot be a future date",
      function (value) {
        const selectedDate = new Date(value);

        const currentDate = new Date();

        return selectedDate <= currentDate;
      }
    ),
  gender: yup.string().required("Gender is required"),
  maritialStatusId: yup.string().required("Marital status is required"),
  isBusiness: yup.string().required("Business is required"),
  medicalPolicy: yup.string().required("Medical policy is required"),
  gnyatiId: yup.string().required("Gnyati is required"),
  MohollaId: yup.string().required("Moholla name is required"),
  res_add1: yup
    .string()
    .required("Residential address is required")
    .max(30, "Address should not exceed 30 characters"),
  res_add2: yup.string().max(30, "Address should not exceed 30 characters"),

  area: yup
    .string()
    .required("Area is required")
    .max(50, "area should not exceed 50 characters"),
  country: yup.string().required("Country is required"),
  stateId: yup.string().required("State is required"),
  cityId: yup.string().required("City is required"),
  // grandFather: yup
  //   .string()
  //   .required("Grand father name is required")
  //   .max(20, "Grand father name cannot exceed 20 characters")
  //   .matches(/^[A-Za-z]+$/, "Grand father name should contain only letters"),
  dataArray: yup.array().of(
    yup.object().shape({
      mem_fname: yup
        .string()
        .required("First name is required")
        .max(20, "First name cannot exceed 20 characters")
        .matches(/^[A-Za-z]+$/, "First name should contain only letters"),
      mem_mname: yup
        .string()
        .required("Middle name is required")
        .max(20, "Middle name cannot exceed 20 characters")
        .matches(/^[A-Za-z]+$/, "Middle name should contain only letters"),
      mem_lname: yup
        .string()
        .required("Last name is required")
        .max(20, "Last name cannot exceed 20 characters")
        .matches(/^[A-Za-z]+$/, "Last name should contain only letters"),
      mem_dob: yup
        .string()
        .required("DOB is required")
        .test(
          "is-not-future-date",
          "DOB cannot be a future date",
          function (value) {
            const selectedDate = new Date(value);

            const currentDate = new Date();

            return selectedDate <= currentDate;
          }
        ),
      mem_isBusiness: yup.string().required("Business is required"),
      mem_hof_relation: yup.string().required("Relation is required"),
      maritialStatus: yup.string().required("Marital status is required"),
      mem_mobile: yup
        .string()
        .required("Number is required")
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid number"),
    })
  ),
});

//profile
const textAndSpaceOnly = (value) =>
  /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;

//validation for admin profile update
export const validationSchemaUpdateAdminProfile = (data) =>
  yup.object().shape({
    fname:
      data == "admin"
        ? yup
            .string()
            .required("First name is required")
            .test(
              "inputEntry",
              "First name allows only characters",
              textAndSpaceOnly
            )
            .test(
              "len",
              "First name allows maximum 20 characters",
              (val) => val.length <= 20
            )
        : yup.string(),
    lname:
      data == "admin"
        ? yup
            .string()
            .required("Last name is required")
            .test(
              "inputEntry",
              "Last name allows only characters",
              textAndSpaceOnly
            )
            .test(
              "len",
              "Last name allows maximum 20 characters",
              (val) => val.length <= 20
            )
        : yup.string(),
    email: yup
      .string()
      .required("Email is required")
      .email("Please enter valid email")
      .matches(
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
        "Please enter valid email"
      ),

    mobileno: yup
      .string()
      .required("Number is required")
      // .min(10, "Number should be 10 digits"),
      .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid number"),

    address:
      data == "admin"
        ? yup
            .string()
            .max(200, "Address is too long (maximum 200 characters)")
            .required("Address is required")
        : yup.string(),
  });

//validation for admin profile update
export const validationSchemaUpdateUserProfile = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .max(20, "First name cannot exceed 20 characters")
    .matches(/^[A-Za-z]+$/, "First name should contain only letters"),

  middleName: yup
    .string()
    .required("Middle name is required")
    .max(20, "Middle name cannot exceed 20 characters")
    .matches(/^[A-Za-z]+$/, "Middle name should contain only letters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(20, "Last name cannot exceed 20 characters")
    .matches(/^[A-Za-z]+$/, "Last name should contain only letters"),
  email: yup
    .string()
    .email("Please enter valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
    .required("Email is required"),
  mobile: yup
    .string()
    .required("Number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid number"),

  // .min(10, "Number should be 10 digits"),
});

export const validationSchemaSahayTypeMaster = yup.object().shape({
  sahayCategory: yup
    .string()
    .max(20, "Sahay title must be at least 20 characters")
    .matches(/^(?!\s).*$/, "Starting space not allow")
    .required("Sahay title is required"),
});

export const validationSchemaSubSahayTypeMaster = yup.object().shape({
  sahayName: yup
    .string()
    .max(50, "Sub sahay type must be at least 50 characters")
    .required("Sub sahay type is required"),
  sahayCategory: yup
    .string()
    .max(20, "Sub Sahay title must be at least 20 characters")
    .matches(/^(?!\s).*$/, "Starting space not allow")
    .required("Sahay type is required"),
});

export const validationSchemaRollMaster = yup.object().shape({
  roleName: yup
    .string()
    .max(20, "User Role must be at least 20 characters")
    .required("User Role is required"),
  roleParent: yup.string().required("User Type  is required"),
});

export const validationSchemaUserMasterAdd = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter valid email")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Please enter valid email"
    ),
  name: yup
    .string()
    .required("User name is required")
    .matches(
      /^(?=.*[0-9])[a-zA-Z0-9]*$/,
      "User name should be unique, Alphanumeric, allow max 25 characters"
    )
    .test(
      "at-least-one-letter",
      "Username must contain at least one letter",
      (value) => {
        // Test if the username contains at least one letter
        return /[a-zA-Z]/.test(value);
      }
    ),

  password: yup
    .string()
    .required("Password is required")
    .min(8, "Must contain 8 characters")
    .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase letter")
    .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase letter")
    .matches(/^(?=.*\d)/, "Must contain at least one digit")
    .matches(
      /^(?=.*[@$!%*?&])/,
      "Must contain at least one special character (@, $, !, %, *, ?, or &)"
    )
    .test(
      "strong-password",
      "Password must meet all criteria",
      function (value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/.test(value);
      }
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  mobileno: yup
    .string()
    .required("Number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid number"),
  role: yup.string().required("Role is required"),
});

export const validationSchemaUserMasterUpdate = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter valid email")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Please enter valid email"
    ),
  name: yup
    .string()
    .required("User name is required")
    .matches(
      /^(?=.*[0-9])[a-zA-Z0-9]*$/,
      "User name should be unique, Apphanumeric, allow max 25 characters"
    )
    .test(
      "at-least-one-letter",
      "Username must contain at least one letter",
      (value) => {
        // Test if the username contains at least one letter
        return /[a-zA-Z]/.test(value);
      }
    ),
  mobileno: yup
    .string()
    .required("Number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid number"),
  role: yup.string().required("Role is required"),
  // .min(10, "Number should be 10 digits"),
});

export const validationSchemaAdminOtp = yup.object().shape({
  otp: yup
    .string()
    .required("Otp is required")
    .matches(/^[0-9]{4}$/, "Otp must be a 4-digit number"),
});

export const validationSchemaUserOtp = yup.object().shape({
  otp: yup
    .string()
    .required("Otp is required")
    .matches(/^[0-9]{4}$/, "Otp must be a 4-digit number"),
});

export const validationSchemaAdminCmsContactus = yup.object().shape({
  mobileno: yup
    .string()
    .required("Number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid number"),
  email: yup
    .string()
    .email("Please enter valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
    .required("Email is required"),
});

export const validationSchemaAdminCmsTermsAndCondition = yup.object().shape({
  title: yup
    .string()
    .max(60, "Title must be at list 60 characters")
    .required("Title is required"),
  description: yup.string().required("Description is required"),
});

export const validationSchemaAdminCmsPrivacyAndPolicy = yup.object().shape({
  title: yup
    .string()
    .max(60, "Title must be at list 60 characters")
    .required("Title is required"),
  description: yup.string().required("Description is required"),
});

export const validationSchemaPreviousSahay = () =>
  yup.object().shape({
    SahayType: yup.string().required("Sahay type is required"),
    subSahayType: yup.string().required("Sub sahay type is required"),
    applicantId: yup.string().required("Name of applicant is required"),
    yearOfSahay: yup.string().required("Year of sahay is required"),
    familyId: yup.string().required("Family Id is required"),
    SahayAmmount: yup.number().required("Sahay Amount is required"),
  });

export const validationSchemaPreviousSahayRepayDetail = () =>
  yup.object().shape({
    SahayType: yup.string().required("Sahay type is required"),
    subSahayType: yup.string().required("Sub sahay type is required"),

    applicantId: yup.string().required("Name of applicant is required"),
    yearOfSahay: yup.string().required("Year of sahay is required"),
    // repayDetails: yup.string().required("Repay Details is required"),
    familyId: yup.string().required("Family Id is required"),
    startDate: yup.date().required("Start Date is required"),
    SahayAmmount: yup.number().required("Sahay Amount is required"),
    totalMonth: yup.number().required("Total Month is required"),
  });

export const validationSchemaDisbursement = yup.object().shape({
  RepayDetail: yup.string(),
  BankIssueName: yup.string().required("Bank Issue Name is required"),
  ChequeNo: yup.string().required("Cheque No is required"),
  chequeDate: yup.date().required("Cheque Date is required"),
  VoucherNo: yup.string().required("Voucher No is required"),
  checkIssueDate: yup.date().required("Cheque Issue Date is required"),
});
export const validationSchemaApproveAmoumt = yup.object().shape({
  approvedAmount: yup.number().required("Approve amount is required"),
});
