import React, { useEffect, useState } from "react";
import Index from "../../../container/Index";
import PagesIndex from "../../../container/PagesIndex";
import "../../../container/admin/pages/dashboardLayout/dashboard.css";
import { useSelector } from "react-redux";

function Sidebar({ open, setOpen, windowSize }) {
  //all state
  const [opens, setOpens] = useState(false);
  const [report, setReport] = useState(false);
  const [loader, setLoader] = useState(true);

  const location = PagesIndex.useLocation();

  const handleCloseSidebar = () => {
    console.log("windowSize", windowSize);
    if (windowSize) {
      setOpen(!open);
    }
  };

  //cms
  const handleClickCms = () => {
    setOpens(!opens);
  };

  //report
  const handleClickReport = () => {
    setReport(!report);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);
  useEffect(() => {
    let width = window.innerWidth;
    console.log(width, "width");
  }, [window.innerWidth]);

  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();
  JSON.parse(localStorage.getItem("token"));
  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  return (
    <>
      <Index.Box
        className={`sidebar-main ${!open ? "active" : "sidebar-none"}`}
      >
        <Index.Box className="sidebar-logo">
          <Index.Box className="sidebar-logo-back">
            <img src={PagesIndex.Png.userlogo} alt="logo"></img>
          </Index.Box>
        </Index.Box>
        <Index.Box className="sidebar-links">
          <Index.Box className="sidebar-ul">
            {rollData === "admin" && (
              <Index.Box
                className={`sidebar-li ${
                  location.pathname == "/admin/dashboard" ? "active" : ""
                }`}
              >
                <PagesIndex.Link
                  to="/admin/dashboard"
                  onClick={handleCloseSidebar}
                >
                  {" "}
                  <img src={PagesIndex.Svg.sidebar1} /> Dashboard
                </PagesIndex.Link>
              </Index.Box>
            )}
            {rollPermissionData?.permissionArray?.includes(
              "User Master_view"
            ) || rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ==
                      `/${rollPermissionNameData}/user-master` ||
                    location.pathname ==
                      `/${rollPermissionNameData}/user-master-add` ||
                    location.pathname ==
                      `/${rollPermissionNameData}/user-master-view`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/user-master`}
                    onClick={handleCloseSidebar}
                  >
                    {" "}
                    <img src={PagesIndex.Svg.sidebar2} /> User Master
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {rollPermissionData?.permissionArray?.includes(
              "Sahay Type Master_view"
            ) || rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ==
                    `/${rollPermissionNameData}/type-master`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/type-master`}
                    onClick={handleCloseSidebar}
                  >
                    {" "}
                    <img src={PagesIndex.Svg.sidebar2} /> Sahay Type Master
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {rollPermissionData?.permissionArray?.includes(
              "Sub Sahay Type Master_view"
            ) || rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ==
                    `/${rollPermissionNameData}/subtype-master`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/subtype-master`}
                    onClick={handleCloseSidebar}
                  >
                    <img src={PagesIndex.Svg.sidebar4} />
                    Sub Sahay Type Master
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {rollPermissionData?.permissionArray?.includes(
              "Previous Sahay Application_view"
            ) ||
            rollPermissionData?.permissionArray?.includes(
              "Current Sahay Application_view"
            ) ||
            // rollPermissionNameData === "staff" ||
            rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ===
                    `/${rollPermissionNameData}/sahay-application`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/sahay-application`}
                    onClick={handleCloseSidebar}
                  >
                    {" "}
                    <img src={PagesIndex.Svg.sidebar2} /> Sahay Applications
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {rollPermissionData?.permissionArray?.includes(
              "Disbursement_view"
            ) || rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ==
                    `/${rollPermissionNameData}/disbursement-application`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/disbursement-application`}
                    onClick={handleCloseSidebar}
                  >
                    {" "}
                    <img src={PagesIndex.Svg.sidebar2} /> Disbursement
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {rollPermissionData?.permissionArray?.includes(
              "New Member Master_view"
            ) || rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ==
                    `/${rollPermissionNameData}/new-member/list`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/new-member/list`}
                    onClick={handleCloseSidebar}
                  >
                    <img src={PagesIndex.Svg.sidebar2} />
                    New Member Master
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}

            {rollPermissionData?.permissionArray?.includes(
              "Year Of Sahay Master_view"
            ) || rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ==
                    `/${rollPermissionNameData}/year-of-sahay/view`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/year-of-sahay/view`}
                    onClick={handleCloseSidebar}
                  >
                    <img src={PagesIndex.Svg.sidebar5} />
                    Year of sahay Master
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}

            {rollPermissionData?.permissionArray?.includes(
              "Role Master_view"
            ) || rollPermissionNameData === "admin" ? (
              <>
                <Index.Box
                  className={`sidebar-li ${
                    location.pathname ==
                    `/${rollPermissionNameData}/role-master/list`
                      ? "active"
                      : ""
                  }`}
                >
                  <PagesIndex.Link
                    to={`/${rollPermissionNameData}/role-master/list`}
                    onClick={handleCloseSidebar}
                  >
                    <img src={PagesIndex.Svg.sidebar5} />
                    Role Master
                  </PagesIndex.Link>
                </Index.Box>
              </>
            ) : (
              ""
            )}

            {/* activity log */}

            {/* {rollPermissionData?.permissionArray?.includes(
              "Role Master_view"
            ) || rollPermissionNameData === "admin" ? ( */}
            <>
              <Index.Box
                className={`sidebar-li ${
                  location.pathname == "admin/activity-log" ? "active" : ""
                }`}
              >
                <PagesIndex.Link
                  to="admin/activity-log"
                  onClick={handleCloseSidebar}
                >
                  <img src={PagesIndex.Svg.sidebar5} />
                  Activity Log
                </PagesIndex.Link>
              </Index.Box>
            </>
            {/* ) : (
              ""
            )} */}

            {rollData === "admin" && (
              <Index.ListItem className="">
                <Index.Box className="w-100-initail">
                  <Index.Box className="cms-text" onClick={handleClickCms}>
                    <Index.Box className="cms-arrow-updown">
                      <img
                        src={PagesIndex.Svg.sidebar5}
                        alt="sidebar icon"
                        className="cms-logo"
                      />
                      CMS
                    </Index.Box>
                    {opens ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="">
                    <Index.Collapse
                      in={opens}
                      timeout="auto"
                      unmountOnExit
                      className=""
                    >
                      <Index.List component="div" disablePadding className="">
                        <Index.ListItem
                          className={`cms-listitem ${
                            location.pathname == "/admin/terms-and-condition"
                              ? "active"
                              : ""
                          }`}
                        >
                          <PagesIndex.Link
                            to="/admin/terms-and-condition"
                            className=""
                            onClick={handleCloseSidebar}
                          >
                            Terms and conditions
                          </PagesIndex.Link>
                        </Index.ListItem>
                        <Index.ListItem
                          className={`cms-listitem ${
                            location.pathname == "/admin/privacy-policy"
                              ? "active"
                              : ""
                          }`}
                        >
                          <PagesIndex.Link
                            to="/admin/privacy-policy"
                            className=" "
                            onClick={handleCloseSidebar}
                          >
                            Privacy policy
                          </PagesIndex.Link>
                        </Index.ListItem>
                        <Index.ListItem
                          className={`cms-listitem ${
                            location.pathname == "/admin/contact-us"
                              ? "active"
                              : ""
                          }`}
                        >
                          <PagesIndex.Link
                            to="/admin/contact-us"
                            className=" "
                            onClick={handleCloseSidebar}
                          >
                            Contact Us
                          </PagesIndex.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {rollPermissionNameData === "admin" ||
            rollPermissionData?.permissionArray?.includes(
              "Committee Report_view"
            ) ||
            rollPermissionData?.permissionArray?.includes(
              "Sub Sahay Wise Application Report_view"
            ) ||
            rollPermissionData?.permissionArray?.includes(
              "Sahay Family Report_view"
            ) ||
            rollPermissionData?.permissionArray?.includes(
              "Sahay Detail Sahay Wise Report_view"
            ) ||
            rollPermissionData?.permissionArray?.includes(
              "Disbursement Report_view"
            ) ||
            rollPermissionData?.permissionArray?.includes("Ecs Report_view") ||
            rollPermissionData?.permissionArray?.includes(
              "Family Wise Report_view"
            ) ||
            rollPermissionData?.permissionArray?.includes(
              "Committee Report_view"
            ) ||
            rollPermissionData?.permissionArray?.includes(
              "Repayment Report_view"
            ) ? (
              <Index.ListItem className="">
                <Index.Box className="w-100-initail">
                  <Index.Box className="cms-text" onClick={handleClickReport}>
                    <Index.Box className="cms-arrow-updown">
                      <img
                        src={PagesIndex.Svg.sidebar5}
                        alt="sidebar icon"
                        className="cms-logo"
                      />
                      Reports
                    </Index.Box>
                    {report ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="">
                    <Index.Collapse
                      in={report}
                      timeout="auto"
                      unmountOnExit
                      className=""
                    >
                      <Index.List component="div" disablePadding className="">
                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Sahay Family Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/family-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/family-report`}
                                onClick={handleCloseSidebar}
                              >
                                Sahay Family Report
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}

                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Sahay Detail Sahay Wise Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/sahay-wise-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/sahay-wise-report`}
                                onClick={handleCloseSidebar}
                              >
                                Sahay Detail Sahay Wise
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}
                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Sub Sahay Wise Application Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/application-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/application-report`}
                                onClick={handleCloseSidebar}
                              >
                                Sub Sahay wise Application
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}
                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Disbursement Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/disbursement-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/disbursement-report`}
                                onClick={handleCloseSidebar}
                              >
                                Disbursement Report
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}

                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Ecs Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/esc-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/esc-report`}
                                onClick={handleCloseSidebar}
                              >
                                ECS Report
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}
                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Family Wise Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/family-wise-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/family-wise-report`}
                                onClick={handleCloseSidebar}
                              >
                                Family Wise Sahay Wise Detail
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}
                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Committee Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/commite-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/commite-report`}
                                onClick={handleCloseSidebar}
                              >
                                Committee Report
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}
                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Repayment Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/repayment-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/repayment-report`}
                                onClick={handleCloseSidebar}
                              >
                                Repayment Report
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}
                        {rollData === "admin" ||
                        rollPermissionData?.permissionArray?.includes(
                          "Sahay Application Procedure Report_view"
                        ) ||
                        rollPermissionNameData === "admin" ? (
                          <>
                            <Index.ListItem
                              className={`cms-listitem ${
                                location.pathname ==
                                `/${rollPermissionNameData}/newreport-report`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <PagesIndex.Link
                                to={`/${rollPermissionNameData}/newreport-report`}
                                onClick={handleCloseSidebar}
                              >
                                Sahay Application Procedure Report
                              </PagesIndex.Link>
                            </Index.ListItem>
                          </>
                        ) : (
                          ""
                        )}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sidebar;

// import React, { useEffect, useState } from "react";
// import Index from "../../../container/Index";
// import PagesIndex from "../../../container/PagesIndex";
// import "../../../container/admin/pages/dashboardLayout/dashboard.css";
// import { useSelector } from "react-redux";

// function Sidebar({ open, setOpen }) {
//   //all state
//   const [opens, setOpens] = useState(false);

//   const location = PagesIndex.useLocation();

//   //cms
//   const handleClickCms = () => {
//     setOpens(!opens);
//   };

//   const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

//   const state = useSelector((state) => state);
//   // useEffect(() => {
//   //   var rollData = localStorage?.getItem("RollName");

//   // }, []);

//   // /

//   return (
//     <>
//       <Index.Box
//         className={`sidebar-main ${!open ? "active" : "sidebar-none"}`}
//       >
//         <Index.Box className="sidebar-logo">
//           <Index.Box className="sidebar-logo-back">
//             <img src={PagesIndex.Png.userlogo} alt="logo"></img>
//           </Index.Box>
//         </Index.Box>
//         <Index.Box className="sidebar-links">
//           <Index.Box className="sidebar-ul">
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/dashboard" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/dashboard">
//                   {" "}
//                   <img src={PagesIndex.Svg.sidebar1} /> Dashboard
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/user-master" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/user-master">
//                   {" "}
//                   <img src={PagesIndex.Svg.sidebar2} /> User Master
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/type-master" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/type-master">
//                   {" "}
//                   <img src={PagesIndex.Svg.sidebar2} /> Sahay Type Master
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/subtype-master" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/subtype-master">
//                   <img src={PagesIndex.Svg.sidebar4} />
//                   Sub Sahay Type Master
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {(rollData === "staff" ||
//               rollData === "admin" ||
//               rollData === "committee") && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/sahay-application"
//                     ? "active"
//                     : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/sahay-application">
//                   {" "}
//                   <img src={PagesIndex.Svg.sidebar2} /> Sahay Applications
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {(rollData === "staff" ||
//               rollData === "admin" ||
//               rollData === "committee") && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/disbursement-application"
//                     ? "active"
//                     : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/disbursement-application">
//                   {" "}
//                   <img src={PagesIndex.Svg.sidebar2} /> Disbursement
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/new-member/list" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/new-member/list">
//                   <img src={PagesIndex.Svg.sidebar2} />
//                   New Member Master
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/bank-master" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/bank-master">
//                   <img src={PagesIndex.Svg.sidebar8} /> Bank Master
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/year-of-sahay/view"
//                     ? "active"
//                     : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/year-of-sahay/view">
//                   <img src={PagesIndex.Svg.sidebar5} />
//                   Year of sahay Master
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin/role-master/list" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/role-master/list">
//                   <img src={PagesIndex.Svg.sidebar5} />
//                   Role Master
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.Box
//                 className={`sidebar-li ${
//                   location.pathname == "/admin//role-permission" ? "active" : ""
//                 }`}
//               >
//                 <PagesIndex.Link to="/admin/role-permission">
//                   <img src={PagesIndex.Svg.sidebar5} />
//                   Role Permission
//                 </PagesIndex.Link>
//               </Index.Box>
//             )}
//             {rollData === "admin" && (
//               <Index.ListItem className="">
//                 <Index.Box className="w-100-initail">
//                   <Index.Box className="cms-text" onClick={handleClickCms}>
//                     <Index.Box className="cms-arrow-updown">
//                       <img
//                         src={PagesIndex.Svg.sidebar5}
//                         alt="sidebar icon"
//                         className="cms-logo"
//                       />
//                       CMS
//                     </Index.Box>
//                     {opens ? (
//                       <Index.ExpandLess className="expandless-icon" />
//                     ) : (
//                       <Index.ExpandMore className="expandmore-icon" />
//                     )}
//                   </Index.Box>
//                   <Index.Box className="">
//                     <Index.Collapse
//                       in={opens}
//                       timeout="auto"
//                       unmountOnExit
//                       className=""
//                     >
//                       <Index.List component="div" disablePadding className="">
//                         <Index.ListItem
//                           className={`cms-listitem ${
//                             location.pathname == "/admin/terms-and-condition"
//                               ? "active"
//                               : ""
//                           }`}
//                         >
//                           <PagesIndex.Link
//                             to="/admin/terms-and-condition"
//                             className=""
//                           >
//                             Terms and conditions
//                           </PagesIndex.Link>
//                         </Index.ListItem>
//                         <Index.ListItem
//                           className={`cms-listitem ${
//                             location.pathname == "/admin/privacy-policy"
//                               ? "active"
//                               : ""
//                           }`}
//                         >
//                           <PagesIndex.Link
//                             to="/admin/privacy-policy"
//                             className=" "
//                           >
//                             Privacy policy
//                           </PagesIndex.Link>
//                         </Index.ListItem>
//                         <Index.ListItem
//                           className={`cms-listitem ${
//                             location.pathname == "/admin/contact-us"
//                               ? "active"
//                               : ""
//                           }`}
//                         >
//                           <PagesIndex.Link to="/admin/contact-us" className=" ">
//                             Contact Us
//                           </PagesIndex.Link>
//                         </Index.ListItem>
//                       </Index.List>
//                     </Index.Collapse>
//                   </Index.Box>
//                 </Index.Box>
//               </Index.ListItem>
//             )}
//           </Index.Box>
//         </Index.Box>
//       </Index.Box>
//     </>
//   );
// }

// export default Sidebar;
