import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { getParentRoleMaster } from "../../../../redux/features/AddService";
import { CircularProgress } from "@mui/material";
import PageLoading from "../../../../components/common/pageLoading/PageLoading";
import { useSelector } from "react-redux";

const RoleMasterAdd = () => {
  const { state } = PagesIndex.useLocation();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(null);
  const [roleIdArray, setRoleIdArray] = useState([]);
  const [parentRoleList, setParentRoleList] = useState([]);
  const [rollExist, setRollExist] = useState("");
  const [rollDefineParentData, setRollDefineParentData] = useState([]);
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const updateRollData = location?.state?.RollEditData;
  const rollData = JSON.parse(localStorage?.getItem("RollName"))?.toLowerCase();

  const modules = [
    {
      tag: "Role Master",
      title: "Role Master",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Year Of Sahay Master",
      title: "Year Of Sahay Master",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sahay Type Master",
      title: "Sahay Type Master",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sub Sahay Type Master",
      title: "Sub Sahay Type Master",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },

    {
      tag: "User Master",
      title: "User Master",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },

    {
      tag: "Previous Sahay Application",
      title: "Previous Sahay Application",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "Current Sahay Application",
      title: "Current Sahay Application",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "Disbursement",
      title: "Disbursement",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "New Member Master",
      title: "New Member Master",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sahay Family Report",
      title: "Sahay Family Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sahay Detail Sahay Wise Report",
      title: "Sahay Detail Sahay Wise Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sub Sahay Wise Application Report",
      title: "Sub Sahay Wise Application Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Disbursement Report",
      title: "Disbursement Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Ecs Report",
      title: "Ecs Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Family Wise Report",
      title: "Family Wise Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Committee Report",
      title: "Committee Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Repayment Report",
      title: "Repayment Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "Sahay Application Procedure Report",
      title: "Sahay Application Procedure Report",
      isList: "false",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    // {
    //   tag: "Bank Master",
    //   title: "Bank Master",
    //   isView: "true",
    //   isAdd: "true",
    //   isEdit: "true",
    //   isDelete: "true",
    // },

    // {
    //   tag: "Role Permission",
    //   title: "Role Permission",
    //   isView: "true",
    //   isAdd: "true",
    //   isEdit: "true",
    //   isDelete: "true",
    // },
  ];

  const checkUncheckAllType = (setFieldValue, permission, action, tag) => {
    if (action === "add") {
      let newData = [
        // tag + "_list",
        tag + "_view",
        tag + "_add",
        tag + "_edit",
        tag + "_delete",
      ];

      const updatedData = [...permission, ...newData];
      setFieldValue("permissionArray", updatedData);
    } else {
      let perm = [
        // tag + "_list",
        tag + "_view",
        tag + "_add",
        tag + "_edit",
        tag + "_delete",
      ];
      const data = permission?.filter((ele) => {
        return !perm.includes(ele);
      });
      setFieldValue("permissionArray", data);
    }
  };

  let initialValues = {
    roleName: updateRollData?.roleId ? updateRollData?.roleName : "",
    permissionArray: updateRollData?.roleId
      ? updateRollData?.permissionArray
      : [],
    roleParent: updateRollData?.roleParentId
      ? updateRollData?.roleParentId
      : "",
  };

  //handle submit
  const handleFormSubmit = async (values, { setFieldValue }) => {
    setPageLoader(true);
    const sendRollData = {
      roleName: values?.roleName,
      roleParent: values?.roleParent,
      permissionArray: values?.permissionArray,
    };
    if (updateRollData?.roleId) {
      sendRollData["roleId"] = updateRollData?.roleId;
    }
    if (!updateRollData?.roleId) {
      try {
        const res = await DataService.post(
          Api.ADMIN_ADD_ROLE_DATA,
          sendRollData
        );
        if (res?.data?.status == 201) {
          toast.success(res?.data?.message);
          navigate("/admin/role-master/list");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        if (error?.response?.data?.status !== 409) {
          toast.error(error?.response?.data?.message || error.message);
        }
        if (error?.response?.data?.status == 409) {
          setRollExist(error?.response?.data?.message);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } else {
      try {
        const res = await DataService.post(
          Api.ADMIN_ADD_ROLE_DATA,
          sendRollData
        );
        if (res?.data?.status == 201) {
          toast.success(res?.data?.message);
          navigate(`/${rollData}/role-master/list`);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } catch (error) {
        if (error?.response?.data?.status !== 409) {
          toast.error(error?.response?.data?.message || error.message);
        }
        if (error?.response?.data?.status == 409) {
          setRollExist(error?.response?.data?.message);
        }
        setTimeout(() => {
          setLoading(false);
          setPageLoader(false);
        }, 1000);
      }
    }
  };

  const handleMenuOpen = () => {
    setLoader(true);

    setLoadingTimeout(
      parentRoleList &&
        setTimeout(() => {
          setLoader(false);
        }, 1000)
    );
  };
  const handleMenuClose = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  //Get Parent role
  const getParentRoleList = () => {
    dispatch(getParentRoleMaster()).then((res) => {
      if (res?.payload?.status == 200) {
        setParentRoleList(res?.payload?.data);
      } else {
        setParentRoleList([]);
      }
    });
  };
  useEffect(() => {
    getParentRoleList();
  }, []);

  useEffect(() => {
    if (updateRollData?.roleParentId) {
      setRollDefineParentData(updateRollData?.roleParentName);
    }
  }, []);

  const { rollPermissionData } = useSelector((state) => state.admin);
  const rollPermissionNameData =
    rollPermissionData?.findData?.parentRoleName?.toLowerCase();

  if (
    rollPermissionData?.permissionArray?.includes("Role Master_add") ||
    rollPermissionData?.permissionArray?.includes("Role Master_edit") ||
    rollPermissionNameData === "admin"
  ) {
  } else {
    navigate(`/${rollData}/sahay-application`);
  }

  return (
    <>
      {pageLoader ? (
        <PageLoading />
      ) : (
        <>
          <Index.Box className="page-content-box">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {state ? state?.edit : "Add"} User Role
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <PagesIndex.Formik
                // enableReinitialize
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={PagesIndex.validationSchemaRollMaster}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  errors,
                  handleBlur,
                  touched,
                  // isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="">
                      <Index.Grid
                        container
                        spacing={2}
                        className="main-box-heading"
                      >
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Role Type*
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Select
                              fullWidth
                              name="role"
                              id="hgh"
                              value={values?.roleParent}
                              onOpen={handleMenuOpen}
                              onClose={handleMenuClose}
                              onChange={(e) => {
                                setFieldValue("roleParent", e.target.value);
                                const filterData =
                                  parentRoleList &&
                                  parentRoleList?.filter((ele) => {
                                    return ele?.id === e.target.value;
                                  });
                                setRollDefineParentData(
                                  filterData[0]?.parentRole
                                );
                              }}
                            >
                              {loader ? (
                                <Index.Box className="loader-container">
                                  <CircularProgress className="small-circular-progress" />
                                </Index.Box>
                              ) : (
                                parentRoleList?.map((data) => (
                                  <Index.MenuItem
                                    key={data?.id}
                                    value={data?.id}
                                  >
                                    {data?.parentRole}
                                  </Index.MenuItem>
                                ))
                              )}
                            </Index.Select>
                          </Index.Box>
                          {errors?.roleParent && touched.roleParent && (
                            <p className="error-text">{errors?.roleParent}</p>
                          )}
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            User Role*
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              name="roleName"
                              onBlur={handleBlur}
                              value={values.roleName}
                              hiddenLabel
                              onChange={(e) => {
                                let inputValue = e.target.value; // Remove extra spaces at the beginning and end
                                if (inputValue.length <= 20) {
                                  setFieldValue("roleName", inputValue);
                                }
                              }}
                              id="filled-hidden-label-normal"
                              placeholder="Enter Roll Type"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              autoComplete="off"
                            />
                          </Index.Box>
                          {errors?.roleName && touched.roleName && (
                            <p className="error-text">{errors?.roleName}</p>
                          )}
                          {rollExist !== "" && values?.roleName !== "" && (
                            <p className="error-text">{rollExist}</p>
                          )}
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={3}>
                          <Index.Box className="back-btn-box">
                            <Index.Box className="common-button blue-button flex-start save-btn mt-btn-spacing">
                              <Index.Button
                                variant="contained"
                                type="submit"
                                disabled={loading}
                              >
                                Submit
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="common-button blue-button flex-start save-btn mt-btn-spacing ">
                              <Index.Button
                                variant="contained"
                                onClick={() =>
                                  navigate(`/${rollData}/role-master/list`)
                                }
                              >
                                Back
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Grid>
                      <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        sx={{ marginTop: "15px" }}
                      >
                        {modules
                          ?.filter((row) => {
                            return rollDefineParentData == "committee"
                              ? row?.tag !== "Previous Sahay Application"
                              : row;
                          })
                          ?.map((row, index) => (
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                                xl: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="role-card-main">
                                <Index.Box className="role-card-inner-flex">
                                  <Index.Box className="role-titles-main">
                                    <Index.Typography className="role-titles">
                                      {row?.title}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="roles-data-main-flex">
                                    <Index.Box className="roles-data-box">
                                      <Index.Box className="add-role-flex">
                                        <Checkbox
                                          className="check-box-role"
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              checkUncheckAllType(
                                                setFieldValue,
                                                values?.permissionArray,
                                                "add",
                                                row?.tag
                                              );
                                            } else {
                                              checkUncheckAllType(
                                                setFieldValue,
                                                values?.permissionArray,
                                                "remove",
                                                row?.tag
                                              );
                                            }
                                          }}
                                        />
                                        <Index.Box className="role-data-title">
                                          All
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                    {/* <Index.Box className="roles-data-box">
                                      <Index.Box className="add-role-flex">
                                        <Index.Checkbox
                                          className="check-box-role"
                                          name="permissionArray"
                                          // onChange={handleChange}
                                          disabled={row?.isView === "false"}
                                          checked={
                                            values?.permissionArray?.includes(
                                              row?.tag + "_view"
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            if (
                                              values?.permissionArray?.includes(
                                                row?.tag + "_view"
                                              )
                                            ) {
                                              const newData =
                                                values?.permissionArray?.filter(
                                                  function (item) {
                                                    return (
                                                      item !==
                                                      row?.tag + "_view"
                                                    );
                                                  }
                                                );
                                              setFieldValue(
                                                "permissionArray",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.permissionArray,
                                              ];
                                              newData.push(row?.tag + "_view");
                                              setFieldValue(
                                                "permissionArray",
                                                newData
                                              );
                                            }
                                          }}
                                        />
                                        <Index.Box className="role-data-title">
                                          List
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box> */}
                                    <Index.Box className="roles-data-box">
                                      {row?.isView === "true" ? (
                                        <Index.Box className="add-role-flex">
                                          <Index.Checkbox
                                            className="check-box-role"
                                            name="permissionArray"
                                            // onChange={handleChange}
                                            disabled={row?.isView === "false"}
                                            checked={
                                              values?.permissionArray?.includes(
                                                row?.tag + "_view"
                                              )
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              if (
                                                values?.permissionArray?.includes(
                                                  row?.tag + "_view"
                                                )
                                              ) {
                                                const newData =
                                                  values?.permissionArray?.filter(
                                                    function (item) {
                                                      return (
                                                        item !==
                                                        row?.tag + "_view"
                                                      );
                                                    }
                                                  );
                                                setFieldValue(
                                                  "permissionArray",
                                                  newData
                                                );
                                              } else {
                                                const newData = [
                                                  ...values?.permissionArray,
                                                ];
                                                newData.push(
                                                  row?.tag + "_view"
                                                );
                                                setFieldValue(
                                                  "permissionArray",
                                                  newData
                                                );
                                              }
                                            }}
                                          />
                                          <Index.Box className="role-data-title">
                                            Read
                                          </Index.Box>
                                        </Index.Box>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Box>
                                    {(rollDefineParentData == "committee" &&
                                      row?.tag ==
                                        "Current Sahay Application") ||
                                    (rollDefineParentData == "admin" &&
                                      row?.tag ==
                                        "Current Sahay Application") ||
                                    (rollDefineParentData == "admin" &&
                                      row?.tag ==
                                        "Previous Sahay Application") ||
                                    (rollDefineParentData == "committee" &&
                                      row?.tag == "Disbursement") ||
                                    (rollDefineParentData == "admin" &&
                                      row?.tag == "Disbursement") ? (
                                      <Index.Box className="roles-data-box">
                                        <Index.Box className="add-role-flex">
                                          <Index.Box className="role-data-title"></Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    ) : (
                                      <Index.Box className="roles-data-box">
                                        <Index.Box className="add-role-flex">
                                          <Index.Checkbox
                                            className="check-box-role"
                                            name="permissionArray"
                                            disabled={row?.isAdd === "false"}
                                            checked={
                                              row?.isAdd === "true"
                                                ? values?.permissionArray?.includes(
                                                    row?.tag + "_add"
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            onChange={(e) => {
                                              if (
                                                values?.permissionArray?.includes(
                                                  row?.tag + "_add"
                                                )
                                              ) {
                                                const newData =
                                                  values?.permissionArray?.filter(
                                                    function (item) {
                                                      return (
                                                        item !==
                                                        row?.tag + "_add"
                                                      );
                                                    }
                                                  );
                                                setFieldValue(
                                                  "permissionArray",
                                                  newData
                                                );
                                              } else {
                                                const newData = [
                                                  ...values?.permissionArray,
                                                ];
                                                newData.push(row?.tag + "_add");
                                                if (
                                                  !values?.permissionArray?.includes(
                                                    row?.tag + "_view"
                                                  ) &&
                                                  row?.isView === "true"
                                                ) {
                                                  newData.push(
                                                    row?.tag + "_view"
                                                  );
                                                }
                                                setFieldValue(
                                                  "permissionArray",
                                                  newData
                                                );
                                              }
                                            }}
                                          />
                                          <Index.Box className="role-data-title">
                                            Create
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    )}

                                    {(rollDefineParentData == "committee" &&
                                      row?.tag ==
                                        "Current Sahay Application") ||
                                    (rollDefineParentData == "admin" &&
                                      row?.tag ==
                                        "Current Sahay Application") ||
                                    (rollDefineParentData == "admin" &&
                                      row?.tag ==
                                        "Previous Sahay Application") ||
                                    (rollDefineParentData == "committee" &&
                                      row?.tag == "Disbursement") ||
                                    (rollDefineParentData == "admin" &&
                                      row?.tag == "Disbursement") ? (
                                      <Index.Box className="roles-data-box">
                                        <Index.Box className="add-role-flex">
                                          <Index.Box className="role-data-title"></Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    ) : (
                                      <Index.Box className="roles-data-box">
                                        {row?.isEdit === "true" ? (
                                          <Index.Box className="add-role-flex">
                                            <Index.Checkbox
                                              className="check-box-role"
                                              name="permissionArray"
                                              disabled={row?.isEdit === "false"}
                                              checked={
                                                row?.isEdit === "true"
                                                  ? values?.permissionArray?.includes(
                                                      row?.tag + "_edit"
                                                    )
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              onChange={(e) => {
                                                if (
                                                  values?.permissionArray?.includes(
                                                    row?.tag + "_edit"
                                                  )
                                                ) {
                                                  const newData =
                                                    values?.permissionArray?.filter(
                                                      function (item) {
                                                        return (
                                                          item !==
                                                          row?.tag + "_edit"
                                                        );
                                                      }
                                                    );
                                                  setFieldValue(
                                                    "permissionArray",
                                                    newData
                                                  );
                                                } else {
                                                  const newData = [
                                                    ...values?.permissionArray,
                                                  ];
                                                  newData.push(
                                                    row?.tag + "_edit"
                                                  );
                                                  if (
                                                    !values?.permissionArray?.includes(
                                                      row?.tag + "_view"
                                                    ) &&
                                                    row?.isView === "true"
                                                  ) {
                                                    newData.push(
                                                      row?.tag + "_view"
                                                    );
                                                  }
                                                  setFieldValue(
                                                    "permissionArray",
                                                    newData
                                                  );
                                                }
                                              }}
                                            />
                                            <Index.Box className="role-data-title">
                                              Update
                                            </Index.Box>
                                          </Index.Box>
                                        ) : (
                                          ""
                                        )}
                                      </Index.Box>
                                    )}
                                    <Index.Box className="roles-data-box">
                                      {row?.isDelete === "true" ? (
                                        <Index.Box className="add-role-flex">
                                          <Index.Checkbox
                                            className="check-box-role"
                                            name="permissionArray"
                                            disabled={row?.isDelete === "false"}
                                            checked={
                                              row?.isDelete === "true"
                                                ? values?.permissionArray?.includes(
                                                    row?.tag + "_delete"
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            onChange={(e) => {
                                              if (
                                                values?.permissionArray?.includes(
                                                  row?.tag + "_delete"
                                                )
                                              ) {
                                                const newData =
                                                  values?.permissionArray?.filter(
                                                    function (item) {
                                                      return (
                                                        item !==
                                                        row?.tag + "_delete"
                                                      );
                                                    }
                                                  );
                                                setFieldValue(
                                                  "permissionArray",
                                                  newData
                                                );
                                              } else {
                                                const newData = [
                                                  ...values?.permissionArray,
                                                ];
                                                newData.push(
                                                  row?.tag + "_delete"
                                                );
                                                if (
                                                  !values?.permissionArray?.includes(
                                                    row?.tag + "_view"
                                                  ) &&
                                                  row?.isView === "true"
                                                ) {
                                                  newData.push(
                                                    row?.tag + "_view"
                                                  );
                                                }
                                                setFieldValue(
                                                  "permissionArray",
                                                  newData
                                                );
                                              }
                                            }}
                                          />
                                          <Index.Box className="role-data-title">
                                            Delete
                                          </Index.Box>
                                        </Index.Box>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          ))}
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </>
      )}
    </>
  );
};

export default RoleMasterAdd;
