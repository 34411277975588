const Api = {
  //admin auth
  ADMIN_LOGIN: "admin/login",
  FORGOT_PASSWORD: "admin/forgotPassword",
  VARIFY_OTP: "OtpVerify",
  RESET_PASSWORD: "admin/resetPassword",
  CHANGE_PASSWORD: "admin/changePassword",
  ADMIN_LOGOUT: "admin/adminLogout",
  //admin auth end
  //sahay type
  ADMIN_GET_SAHAYTYPE: "admin/sahayMaster",
  //sub sahay type
  ADMIN_ADD_SUBSAHAYTYPE: "admin/subSahayMaster",
  //bank master
  GET_ALL_BANKMASTER_LIST: `common/getBankMasterList`,
  //all new member add update list
  GETMOHALLAMASTERLIST: "common/getMoholloMasterList",
  GETBLOODMASTERLIST: "common/getBloodgroupMasterList",
  GETMARITALSTATUSLIST: "common/getdMaritalStatusList",
  GETGNYATIMASTERLIST: "common/getGnyatiMasterList",
  GETRELATIONMASTERLIST: "common/getRelationMasterList",
  GETSTATEMASTERLIST: "common/getStateMasterList",
  GETCOUNTRYMASTERLIST: "common/getCountryMasterList",
  GETCITYMASTERLIST: "common/getCityMasterList",
  //  end all new member add update list
  GETALLNEWMEMBERLIST: "admin/membersList",
  ADDFAMILYHEAD: "admin/users/addFamilyHead",
  ADDFAMILYMEMBER: "admin/addFamilyMember",
  GETDATALIST: "admin/getDataList",
  //New member single delete
  NEW_MEMBER_DELETE_LIST: "admin/deleteSingleUser",
  //Year
  // GET_ALL_YEAR_OF_SAHAY: "admin/getYearOfSahayList",
  GET_ALL_YEAR_OF_SAHAY: "common/yearOfSahay",
  ADD_YEAR_OF_SAHAY: "admin/addYearOfSahay",
  UPDATE_YEAR_OF_SAHAY: "admin/updateYearOfSahay/",
  DELETE_YEAR_OF_SAHAY: "admin/deleteSingleYearOfSahay/",
  //Sahay Application
  GET_ALL_SAHAY_APPLICATIONS: "user/sahayApplications",

  //CMS
  GET_CMS_LIST: "admin/getCMSList",
  ADD_CMS_DATA: "admin/dataAdd",
  UPDATE_CMS_DATA: "admin/updateData",

  //apply for sahay in user side
  GET_FAMILY_IDS_LIST: `/common/getFamilyIdList`,
  GET_SAHAY_TYPE_LIST: `/common/sahayMasterList`,
  GET_SAHAY_SUB_TYPE_LIST: `/common/subSahayMasterList`,
  GET_FAMILYMEMBERS_BY_FAMILY_ID: `/common/familyMembersByFamilyId`,
  APPLY_FOR_SAHAY: `/user/ApplyForSahay`,

  //user auth
  USER_LOGIN: "user/login",
  VERIFY_OTP: "user/verifyOTP",

  //Year Of Sahay
  LIST_YEAR_OF_SAHAY: "common/yearOfSahay",

  //User update Profile
  USER_UPDATE_PROFILE: "user/updateProfile",

  //Bank Accounts list
  BANK_ACCOUNTS_LIST: "common/bankAccountList",

  //Admin sahay application
  ADMIN_SAHAY_APPICATION_LIST: "admin/getSahayApplicationsAdmin",
  //Admin msg
  // GET_NOTIFICATION:"admin/singleGetNotificationMsg",

  //New Member View
  GET_FAMILY_MEMBER_BY_ID: "admin/getFamilyMembersById",

  // new member get update data
  GETUSERPROFILEUPDATE: "admin/getUserProfile",

  // new member update
  UPDATEFAMILYMEMBER: "admin/updateUserProfile",
  GET_ALL_FAMILY_MEMBER_BY_ID: "admin/getUserProfile",

  //get single sahay application
  GET_SINGLE_SAHAY_APPLICATION: "common/getSahayApplications",

  //admin profile get and update
  GET_ADMIN_PROFILE: "admin/getSingleAdmin",
  GET_ADMIN_PROFILE_UPDATE: "admin/updateAdminProfile",

  //multiple delete listing
  MULTIPLEDELETE_SAHAY_TYPE_LIST: "admin/deleteSahayTypes",
  MULTIPLEDELETE_SAHAY_SUB_TYPE_LIST: "admin/deleteSubSahayTypes",
  MULTIPLEDELETE_NEW_MEMBER_LIST: "admin/deleteUser",
  MULTIPLEDELETE_YEAR_OF_SAHAY: "admin/deleteYearOfSahay",

  //report
  GET_REPORT_DATA: "common/getSubSahayAmount",
  GET_SUB_SAHAY_REPORT_DATA: "common/getSubSahayWiseApplicationStatus",
  GET_DISBURSEMRNT_REPORT_DATA: "common/getDisbursementReport",
  GET_ESC_REPORT_DATA: "common/getECSReport",
  GET_COMMITE_REPORT_DATA: "common/getReportSeven",
  GET_REPAYMENT_DATA: "common/getReportEight",
  GET_NEW_REPORT_DATA: "admin/get-report",
  GET_SUB_SAHAY_WISE_REPORT_DATA: "common/getReportTwo",
  GET_FAMILY_WISE_REPORT_DATA: "common/getPastThreeYearApprovedAmountGet",
  GET_SAHAY_AMOUNT: "common/getSubSahayAmount",

  //get user data for user profile update
  GET_SINGLE_USER_DATA: "user/getUser",

  //admin otp verify
  GET_ADMIN_OTP_DATA: "admin/OtpVerify",

  //resend otp
  USER_RESEND_OTP: "user/resendOTP",

  //edit sahay application user side
  USER_SAHAYAPPLICATION_UPDATE: "user/EditApplyForSahay",

  //roll master
  ADMIN_GET_ROLE_MASTER: "admin/getRoleList",
  ADMIN_GET_PARENT_ROLE_MASTER: "admin/getParentRole",
  ADMIN_UPDATE_ROLE_MASTER: "admin/updateRole",
  ADMIN_DELETE_ROLE_MASTER: "admin/deleteRole",
  ADMIN_GET_SINGLE_ROLE_MASTER: "admin/getSingleRole",
  ADMIN_ADD_ROLE_MASTER: "admin/addRole",

  //user master
  ADMIN_GET_USER_MASTER: "admin/getNewUserList",
  ADMIN_DELETE_USER_MASTER: "admin/deleteNewUser",
  ADMIN_ADD_USER_MASTER: "admin/addUser",
  ADMIN_UPDATE_USER_MASTER: "admin/updateUser",
  ADMIN_GET_SINGLE_USER_MASTER: "admin/getSingleUser",
  ADMIN_ACTIVE_DEACTIVE_USER_MASTER: "admin/userActiveDeactive",

  //check status
  CHECK_SAHAY_APPLICATION_STATUS: "user/updateSahayApplication",

  ///////// PREVIOUS SAHAY APISS
  VASTIPATRAK_FAMILY_ID_LIST: "common/getList",
  FAMILY_ID_LIST: "common/get-FamilyidList",
  ADD_PREVIOUS_SAHAY_DATA: "admin/ApplyForSahayByStaff",
  GET_PREVIOUS_DATA_LIST: "admin/getSahayApplicationsListFromStaff",
  GET_Current_Coommittee_DATA_LIST: "user/getSahayApplicationFromCommittee",
  GET_UPDATED_PREVIOUS_DATA: "admin/getSingleSahayApplication",
  CHECK_PREVIOUS_SAHAY_APPLICATION_STATUS: "user/updateSahayApplication",
  GET_DISBURSEMENT_DATA_LIST: "user/getApplicationForDisbursment",
  GET_DISBURSEMENT_DATA_LIST_ADMIN: "admin/getApplicationForDisbursment",
  ADD_DISBURSEMENT_DATA: "user/applyForDisbursment",
  VIEW_DISBURSEMENT_DATA: "user/getSingleDisbursedApplication",
  // ADMIN_ADD_ROLE_DATA: "admin/addUpdateRolePermission",
  ADMIN_ADD_ROLE_DATA: "admin/addRoleAndPermission",
  GET_PREVIOUS_DATA_LIST_ADMIN: "admin/getSahayApplicationFromCommittee",
  UPLOAD_CSV_DATA_BULK_IMPORT: "admin/application/bulkImport",
  UPLOAD_CURRENT_CSV_DATA_BULK_IMPORT:
    "admin/application/bulkImportCurrentYear",
  UPLOAD_FAMILY_MEMBER_CSV_DATA_BULK_IMPORT:
    "admin/application/bulkImportFamilyMember",

  //Update Sahay Application
  UPDATE_SAHAY_APPLICATION: "user/EditApplyForSahay/",

  //Add single disbursment
  ADD_SINGLE_DISBURSMENT: "user/addSingleDisbursment",
  EDIT_SINGLE_DISBURSMENT: "user/updateDisbursedData",
  /// PREVIOUS YEAR SAHAY LIST IN SAHAY APPLICATION VIEW ///
  GET_PREVIOUS_SAHAY_IN_VIEW_SAHAY: "/user/getPreviousAllSahay",

  //Reports Family ID
  GET_ALL_FAMITY_ID_LIST: "/common/familyIdListAll",
  GET_ALL_SUB_SAHAY_LIST: "/common/getAllSubSahay",
  ACTIVE_DEACTIVE_USER_DETAILS: "common/getDataForUser",

  // Update documents
  UPDATE_APPLY_FOR_SAHAY_DOCUMENTS: "/common/updateSahayDocuments",
  //Get Moholla name list
  GET_ALL_FAMILY_ID_WITH_MOHOLLA_NAME_LIST: "common/getMohollaName",
  GET_ALL_FAMILY_ID_WITH_MOBILE_NUMBER_LIST: "common/get-mobile-number-list",

  // dashboard
  GET_ALL_ADMIN_DASHBOARD_LIST: "common/dashboard",
  GET_ALL_ADMIN_ACTIVITY_LIST: "common/getlogActivity",
};

export { Api };
